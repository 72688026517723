<template lang="pug">
  b-row.mb-5
    b-col
      router-view(@property-changed='reloadMachinesPanel')
</template>

<script>
export default {
  name: 'manager-machines-property',
  methods: {
    reloadMachinesPanel() {
      this.$parent.$refs.machinesPanel.panelReload()
    },
  },
}
</script>
